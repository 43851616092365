import React from 'react'
import { Box } from '@material-ui/core';
import NoDataComponent from './NoDataComponent';
import VehicleListItem from './VehicleListItem';
import { cargoStatus, loadApprovalStatus, vehicleCheckStatus } from '../utilities/constants';
import { useTranslation } from 'react-i18next';

const VehicleList = props => {
    const { t } = useTranslation();
    const { onSelected, cargoes, page } = props;
    const selectVehicle = (id) => {
        if (onSelected) onSelected(id)
    }

    if ((cargoes ?? []).length === 0) {
        return <NoDataComponent />
    }

    const getStatus = (cargo) => {
        console.log(cargo)
        let status = null;
        switch (page) {
            case 'vehicle_check': status = cargo.vehicleCheckStatusName; break;
            case 'load_approval_transporter': status = cargo.transporterLoadApprovalStatusName; break;
            case 'load_approval_soc': status = cargo.socLoadApprovalStatusName; break;
            default: status = cargo.statusName; break;
        }
        return status;
    }

    const getSubstatus = cargo => {
        let substatus = null;
        let isInTransit = cargo.status === cargoStatus.in_transit;
        switch (page) {
            case 'vehicle_check': substatus = isInTransit && (cargo.vehicleCheckStatus === vehicleCheckStatus.needApproval || cargo.vehicleCheckStatus === vehicleCheckStatus.rejectedBySoc) ? t('cargoBypassedToInTransit') : null; break;
            case 'load_approval_transporter': substatus = isInTransit && (cargo.transporterLoadApprovalStatus === loadApprovalStatus.needApproval) ? t('cargoBypassedToInTransit') : null; break;
            case 'load_approval_soc': substatus = isInTransit && (cargo.socLoadApprovalStatus === loadApprovalStatus.needApproval) ? t('cargoBypassedToInTransit') : null; break;
            default: substatus = null; break;
        }
        return substatus;
    }

    return (
        <Box p={1}>
            {(cargoes ?? []).map((cargo,index) => {
                return (
                    <VehicleListItem key={index} onClick={() => selectVehicle(cargo.id)} cargo={cargo} status={getStatus(cargo)} substatus={getSubstatus(cargo)} />
                )
            })}
        </Box>
    )
}

export default VehicleList;
