import React, { useEffect, useState } from 'react'
import { Box, Button, Card, CardActions, CardHeader, CardMedia, Checkbox, Container, Divider, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputLabel, ListItemText, MenuItem, Paper, Select, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import CameraIcon from '@material-ui/icons/PhotoCamera'
import { useTranslation } from 'react-i18next';
import { getFile, uploadFile } from '../services/fileService';
import { resizeImage } from '../utilities/fileUtil';
import Alert from './Alert';

const MultiDocumentField = ({ label, disabled, documentIds, onChange, error }) => {
    const { t } = useTranslation()
    const [isUploading, setIsUploading] = useState(false)
    const [imageUrls, setImageUrls] = useState(null)
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
    const [indexOfImageToDelete, setIndexOfImageToDelete] = useState(null)

    const handleFileChange = async (e) => {
        setIsUploading(true)
        try {
            const file = e.target.files[0];
            const resizedFile = await resizeImage(file);
            console.log(file, resizedFile);
            const { data: uploadData } = await uploadFile(resizedFile)
            if (uploadData?.success) {
                const { id, url } = uploadData?.payload[0]
                if (onChange) {
                    const newIds = [...documentIds]
                    newIds.push(id)
                    onChange(newIds)
                }
                const newUrls = [...imageUrls]
                newUrls.push(url)
                setImageUrls(newUrls)
            }
        } catch {
            console.log('Something is wrong when uploading document')
        } finally {
            setIsUploading(false)
        }
    }

    const deleteImage = async () => {
        const newIds = [...documentIds]
        newIds.splice(indexOfImageToDelete, 1)
        onChange(newIds)

        const newUrls = [...imageUrls]
        newUrls.splice(indexOfImageToDelete, 1)
        setImageUrls(newUrls)

        setShowDeleteConfirm(false)
    }

    const fetchImage = async () => {
        setIsUploading(true)
        try {
            const images = []
            for (let i = 0; i < documentIds.length; i++) {
                const { data: imageData } = await getFile(documentIds[i])
                const img = imageData?.payload[0];
                images.push(img?.url)
            }

            setImageUrls(images)
        } catch { }

        setIsUploading(false)
    }

    useEffect(() => {
        if (documentIds) fetchImage(documentIds)
    }, [documentIds])

    const onDeleteClick = (index) => {
        setIndexOfImageToDelete(index)
        setShowDeleteConfirm(true)
    }

    return (
        <>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        action={(
                            <div>
                                <IconButton
                                    component="label"
                                    disabled={disabled}>
                                    <CameraIcon />
                                    <input type="file" accept="image/*" hidden onChange={handleFileChange} />
                                </IconButton>
                            </div>
                        )}
                        subheader={label}
                    />

                    {isUploading &&
                        <Typography style={{ padding: 10, position: 'absolute' }}>{t('loading')}</Typography>
                    }
                    {(!imageUrls || imageUrls.length === 0) && <CardMedia
                        style={{
                            height: 0,
                            paddingTop: '60%', // 16:9
                            backgroundSize: 'contain'
                        }}
                        image=""
                    />}
                    {(imageUrls ?? []).map((imageUrl, index) =>
                        <>
                            <Divider />
                            <CardActions style={{ justifyContent: 'right' }}>
                                <Button size="small" color="secondary" onClick={() => onDeleteClick(index)}>Delete</Button>
                            </CardActions>
                            <CardMedia
                                style={{
                                    height: 0,
                                    paddingTop: '60%', // 16:9
                                    backgroundSize: 'contain'
                                }}
                                image={imageUrl}
                            />

                        </>
                    )}
                </Card>
                {error && <FormHelperText error>{error}</FormHelperText>}
            </Grid>
            <Alert
                message={t('areYouSure')}
                isOpen={showDeleteConfirm}
                onClose={() => setShowDeleteConfirm(false)}
                actions={[
                    { label: t('no'), handler: () => setShowDeleteConfirm(false) },
                    { label: t('yes'), handler: deleteImage }
                ]} />
        </>
    )
}

export default MultiDocumentField