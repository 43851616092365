import React, { useEffect } from 'react'
import { Box, Grid, Paper, Card, CardHeader, CardMedia } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { getFile } from '../../../services/fileService';
import Alert from '../../../components/Alert'

const MultiDocumentItem = ({ label, documentIds }) => {
    const { t } = useTranslation();
    const [imageUrls, setImageUrls] = useState([])
    const [imageUrl, setImageUrl] = useState(null)
    const [isImageDisplayed, setIsImageDisplayed] = useState(false)

    useEffect(() => {
        if (documentIds) fetchImage();
    }, [documentIds])

    const fetchImage = async () => {
        try {
            const urls = []
            for (let i = 0; i < documentIds.length; i++) {
                const docId = documentIds[i]
                const { data: vehicleRegNoData } = await getFile(docId)
                const img = vehicleRegNoData?.payload[0];
                urls.push(img?.url)
            }
            console.log(urls)
            setImageUrls(urls)
        } catch { }
    }

    const onImageClicked = (url) => {
        setImageUrl(url)
        setIsImageDisplayed(true)
    }

    if (!documentIds) return <></>

    return (
        <>
            <Box mb={1}>
                <Paper>
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader subheader={label} />
                            {imageUrls.map(url =>
                                <CardMedia
                                    onClick={() => onImageClicked(url)}
                                    style={{
                                        height: 0,
                                        paddingTop: '60%', // 16:9
                                    }}
                                    image={url}
                                />)}
                        </Card>
                    </Grid>

                </Paper>
            </Box>
            <Alert
                isOpen={isImageDisplayed}
                onClose={() => setIsImageDisplayed(false)}
                actions={[
                    { label: t("done"), handler: () => setIsImageDisplayed(false) },
                ]}
            >
                <img src={imageUrl} className="gallery__img" />
            </Alert>
        </>
    )
}

export default MultiDocumentItem