import React, { useEffect } from 'react'
import { Box, Grid, Paper, Card, CardHeader, CardMedia } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { getFile } from '../../../services/fileService';
import Alert from '../../../components/Alert'

const DocumentItem = ({ label, documentId }) => {
    const { t } = useTranslation();
    const [imageUrl, setImageUrl] = useState(null)
    const [isImageDisplayed, setIsImageDisplayed] = useState(false)

    useEffect(() => {
        if (documentId) fetchImage();
    }, [documentId])

    const fetchImage = async () => {
        try {
            const { data: vehicleRegNoData } = await getFile(documentId)
            const img = vehicleRegNoData?.payload[0];
            setImageUrl(img?.url)
        } catch { }
    }

    if (!documentId) return <></>

    return (
        <>
            <Box mb={1}>
                <Paper>
                    <Grid item xs={12}>
                        <Card onClick={() => setIsImageDisplayed(true)}>
                            <CardHeader subheader={label} />
                            <CardMedia
                                style={{
                                    height: 0,
                                    paddingTop: '60%', // 16:9
                                }}
                                image={imageUrl}
                            />
                        </Card>
                    </Grid>

                </Paper>
            </Box>
            <Alert
                isOpen={isImageDisplayed}
                onClose={() => setIsImageDisplayed(false)}
                actions={[
                    { label: t("done"), handler: () => setIsImageDisplayed(false) },
                ]}
            >
                <img src={imageUrl} className="gallery__img" />
            </Alert>
        </>
    )
}

export default DocumentItem