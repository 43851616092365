import React from 'react'
import Constants from '../utilities/constants'
import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import ic_airplane from '../assets/images/airplane.svg'
import ic_ship from '../assets/images/ship.svg'
import ic_train from '../assets/images/train.svg'
import ic_truck from '../assets/images/truck.svg'
import constants from '../utilities/constants';

const VehicleListItem = ({ cargo, onClick, children, status, substatus }) => {
    const type = cargo.transportationType;
    return (
        <Box key={cargo.id} onClick={onClick} mb={1} className="card card-gradient">
            <Card>
                <CardContent>
                    {type === Constants.transportationType.airplane &&
                        (
                            <Grid container>
                                <Grid item xs={2} container
                                    spacing={0}
                                    direction="column"
                                    justify="center">
                                    <img style={{ width: 32, height: 32 }} src={ic_airplane} alt="air plane" />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant="body2" color="primary"><b>{cargo.awbNo}</b></Typography>
                                </Grid>
                            </Grid>
                        )
                    }
                    {type === Constants.transportationType.ship &&
                        (
                            <Grid container>
                                <Grid item xs={2} container
                                    spacing={0}
                                    direction="column"
                                    justify="center">
                                    <img style={{ width: 32, height: 32 }} src={ic_ship} alt="ship" />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant="body2" color="primary"><b>{cargo.vesselNo}</b></Typography>
                                    <Typography variant="body2">{cargo.containerNo}</Typography>
                                </Grid>
                            </Grid>
                        )
                    }
                    {type === Constants.transportationType.train &&
                        (
                            <Grid container>
                                <Grid item xs={2} container
                                    spacing={0}
                                    direction="column"
                                    justify="center">
                                    <img style={{ width: 32, height: 32 }} src={ic_train} alt="train" />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant="body2" color="primary"><b>{cargo.trainNo}</b></Typography>
                                    <Typography variant="body2">{cargo.containerNo}</Typography>
                                </Grid>
                            </Grid>
                        )
                    }
                    {type === Constants.transportationType.truck &&
                        (

                            <Grid container>
                                <Grid item xs={2} container
                                    spacing={0}
                                    direction="column"
                                    justify="center">
                                    <img style={{ width: 32, height: 32 }} src={ic_truck} alt="truck" />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant="body2" color="primary"><b>{cargo.licenseNo}</b></Typography>
                                    <Typography variant="body2">{cargo.cargoType.name}</Typography>
                                    <Typography variant="body2"><b>{status}</b></Typography>
                                    <Typography variant="caption"><i>{substatus}</i></Typography>
                                </Grid>
                            </Grid>
                        )
                    }
                    {children}
                </CardContent>
            </Card>
        </Box>
    )
}

export default VehicleListItem