import React, { useEffect, useState } from 'react'
import { Box, Button, Card, CardHeader, CardMedia, Checkbox, Container, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputLabel, ListItemText, MenuItem, Paper, Select, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import CameraIcon from '@material-ui/icons/PhotoCamera'
import { useTranslation } from 'react-i18next';
import { getFile, uploadFile } from '../services/fileService';
import { resizeImage } from '../utilities/fileUtil';

const DocumentField = ({ label, disabled, documentId, onChange, error }) => {
    const { t } = useTranslation()
    const [isUploading, setIsUploading] = useState(false)
    const [imageUrl, setImageUrl] = useState(null)
    const handleFileChange = async (e) => {
        setIsUploading(true)
        try {
            const file = e.target.files[0];
            const resizedFile = await resizeImage(file);
            console.log(file, resizedFile);
            const { data: uploadData } = await uploadFile(resizedFile)
            if (uploadData?.success) {
                const { id, url } = uploadData?.payload[0]
                if (onChange) onChange(id)
                setImageUrl(url)
            }
        } catch {
            setIsUploading(false)
        }
    }

    const fetchImage = async () => {
        setIsUploading(true)
        try {
            const { data: vehicleRegNoData } = await getFile(documentId)
            const img = vehicleRegNoData?.payload[0];
            setImageUrl(img?.url)
        } catch { }

        setIsUploading(false)
    }

    useEffect(() => {
        if (documentId) fetchImage(documentId)
    }, [documentId])

    return (
        <Grid item xs={12}>
            <Card>
                <CardHeader
                    action={(
                        <div>
                            <IconButton
                                component="label"
                                disabled={disabled}>
                                <CameraIcon />
                                <input type="file" accept="image/*" hidden onChange={handleFileChange} />
                            </IconButton>
                        </div>
                    )}
                    subheader={label}
                />
                
                {isUploading &&
                    <Typography style={{ padding: 10, position: 'absolute' }}>{t('loading')}</Typography>
                }
                <CardMedia
                    style={{
                        height: 0,
                        paddingTop: '60%', // 16:9
                    }}
                    image={imageUrl}
                />
            </Card>
            {error && <FormHelperText error>{error}</FormHelperText>}
        </Grid>
    )
}

export default DocumentField